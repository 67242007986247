import Link from 'next/link';
import * as Bugsnag from 'lib/bugsnag';

class LinkComponent extends React.Component {

  componentDidCatch(error, info) {
    console.error({ error, info })
    Bugsnag.send(error);
  }

  render() {
    const { href, linkProps, children, pendo } = this.props;

    return (
      <Link href={href}>
        <a data-pendo={pendo} {...linkProps}>
          {children}
        </a>
      </Link>
    )
  }
}

export { LinkComponent as Link };
