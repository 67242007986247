import { makeFeatureHasAccessLevel } from '@a-cloud-guru/packaging-feature-access';
import { ACCESS_LEVELS } from 'constants/packaging';
import { useProfile } from 'hooks';

const useIsPackagingFeatureAuthorised = (featureName) => {
  const { packagingFeatures } = useProfile();

  const featureHasAccessLevel = makeFeatureHasAccessLevel(packagingFeatures);

  const isFeatureDisabled = featureHasAccessLevel(featureName)(ACCESS_LEVELS.DISABLED);
  const isFeatureEnabled = featureHasAccessLevel(featureName)(ACCESS_LEVELS.ENABLED);

  return !isFeatureDisabled && isFeatureEnabled;
};

export { useIsPackagingFeatureAuthorised };
