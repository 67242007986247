import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from 'components/container';
import { QueryError } from 'components/data';

class QueryBoundary extends React.Component {
  static defaultProps = {
    retry: 1
  }

  constructor(props) {
    super(props);
    this.retried = 0;
  }

  componentDidUpdate() {
    const { retry, error, refetch } = this.props;
    if (error && (this.retried < retry)) {
      this.retried++;
      refetch();
    }
  }

  render() {
    const {
      loading,
      error,
      data,
      retry,
      children,
      renderLoading = () => <Container><Spinner size="3rem" /></Container>,
      renderError = () => <QueryError refetch={this.props.refetch} />,
      ...restProps
    } = this.props;

    const shouldRetry = this.retried < retry && error;
    const shouldRenderLoading = renderLoading && (loading || shouldRetry);
    const shouldRenderError = renderError && error && this.retried >= retry;

    return (
      <>
        {shouldRenderLoading && renderLoading() }
        {shouldRenderError && renderError() }
        {(!shouldRenderError && !shouldRenderLoading) && children({ loading, data, error, ...restProps })}
      </>
    )
  }
}

export { QueryBoundary };