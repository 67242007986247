import { useEffect } from 'react';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAuth } from 'hooks/use-auth';

const GET_ALGOLIA_API_KEY_AND_ORG_ID = gql`
  query {
    searchApiKey {
      id
      publicApiKey {
        apiKey
        validUntil
      }
      scopedApiKey {
        apiKey
        validUntil
      }
    }
  }
`;

const useAlgoliaApiKey = () => {
  const { authorized } = useAuth();
  const [execute, { data }] = useLazyQuery(GET_ALGOLIA_API_KEY_AND_ORG_ID);

  useEffect(() => {
    if (authorized) {
      execute();
    }
  }, [authorized]);

  return {
    publicKey: get(data, 'searchApiKey.publicApiKey.apiKey'),
    scopedKey: get(data, 'searchApiKey.scopedApiKey.apiKey'),
  };
};

export { useAlgoliaApiKey };