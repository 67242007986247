const FEATURES = {
  API_ACCESS: 'api-access',
  CERTIFICATION_REPORTING: 'certification-reporting',
  CLOUD_SANDBOXES: 'cloud-sandboxes',
  HANDS_ON_LABS: 'hands-on-labs',
  TEAM_MANAGEMENT: 'team-management',
  STUDY_GROUPS: 'study-groups',
  SKILLS_ASSESSMENT: 'skills-assessment',
  CUSTOM_LEARNING_PATHS: 'custom-learning-paths'
};

const ACCESS_LEVELS = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  UPGRADE: 'UPGRADE'
};

const PACKAGE_NAME = {
  BUSINESS_BASIC: 'Business Basic',
  BUSINESS_PLUS: 'Business Plus'
};

const CLOUD_PLAYGROUND_TCS_CUTOFF_DATE = '2020-04-01T00:00:00.000Z';

export {
  FEATURES,
  ACCESS_LEVELS,
  PACKAGE_NAME,
  CLOUD_PLAYGROUND_TCS_CUTOFF_DATE
};
