const CLOUD_PROVIDERS = {
  'AWS': 'Amazon Web Services',
  'GCP': 'Google Cloud Platform',
  'AZ': 'Microsoft Azure',
  'KUBE': 'Kubernetes'
};

// "developer-friendly-readable-name": "course service courseId"
const AWS_COURSES = {
  'aws-ccp-2021': '1036',
  'aws-csa-associate-2021': '1043',
  'aws-cda': 'aws-cda-2018',
  'aws-csysops': 'aws-csysops-2019',
  'aws-csysops-admin-associate': '1058',
  'aws-csa-pro': 'aws-csa-pro-2019',
  'aws-networking-specialty': 'aws-networking-specialty',
  'aws-certified-data-analytics-specialty': '312375cd-c136-4f1c-81dc-dbdcfff2d06b',
  'aws-certified-security-specialty': 'aws-certified-security-specialty',
  'aws-certified-machine-learning-specialty': 'aws-certified-machine-learning-specialty',
  'aws-certified-alexa-skill-builder-speciality': 'aws-certified-alexa-skill-builder-speciality',
  'aws-certified-devops-engineer-professional': 'aws-certified-devops-engineer-professional-2019',
  'aws-certified-database-specialty-dbs-c01': 'aws-ke001'
};

const GCP_COURSES = {
  'gcp-certified-associate-cloud-engineer': 'gcp-certified-associate-cloud-engineer',
  'gcp-certified-professional-cloud-architect': 'gcp-certified-professional-cloud-architect',
  'gcp-certified-professional-data-engineer': 'gcp-certified-professional-data-engineer',
  'gcp-certified-professional-cloud-network-engineer': 'gcp-certified-professional-cloud-network-engineer'
};

const AZ_COURSES = {
  'az-104': '160303d7-6947-4fbc-9d19-fa304849f92e',
  'az-900': 'az-900-microsoft-azure-fundamentals',
  'az-204': '1007',
  'az-300': '5f033990-3a2e-4fe1-9d90-ecd1d0891deb',
  'az-301': '9c08dc66-41e2-480b-8961-8121d2d747ac',
  'az-500': '6ca00157-fca5-4cfa-b6ec-1c749a1d359f',
  'dp-200': 'b73d3b5f-cd39-477e-8636-7f7258e68d0f',
  'dp-201': '45060f6d-17da-4ad2-bfe0-f5a9da59ae82',
  'dp-900': '1034',
  'az-303-1': 'eb474ca7-9085-47a3-a4fb-a516b61e11db',
  'az-303-2': '6e8afd80-06df-430c-8cca-fcc050e93e97',
  'az-303-3': '79694e38-ae51-4f1d-9bd5-6e5225b06041',
  'az-303-4': '81efff41-34f4-491b-900b-a6904c6d8bab',
  'az-303-5': '1017',
  'az-304': 'azure-rc002'
};

const KUBE_COURSES = {
  'd068441f-75b4-4fe8-a7a6-df9153f24a35': 'd068441f-75b4-4fe8-a7a6-df9153f24a35'
};

const STUDY_GROUP_COURSES = {
  AWS: Object.values(AWS_COURSES),
  GCP: Object.values(GCP_COURSES),
  AZ: Object.values(AZ_COURSES),
  KUBE: Object.values(KUBE_COURSES),
};

const ACCELERATOR_COURSES = [
  AWS_COURSES['aws-ccp-2021'],
  AWS_COURSES['aws-csa-associate-2021'],
  GCP_COURSES['gcp-certified-associate-cloud-engineer'],
  AZ_COURSES['az-900'],
  AZ_COURSES['az-104']
];

const STUDY_PLAN_LENGTH = {
  [AWS_COURSES['aws-ccp-2021']]: 7,
  [AWS_COURSES['aws-csa-associate-2021']]: 14,
  [GCP_COURSES['gcp-certified-associate-cloud-engineer']]: 10,
  [AZ_COURSES['az-900']]: 5,
  [AZ_COURSES['az-104']]: 10
};

export {
  ACCELERATOR_COURSES,
  STUDY_GROUP_COURSES,
  STUDY_PLAN_LENGTH,
  CLOUD_PROVIDERS
};
